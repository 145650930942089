import React, { useState, useEffect } from 'react'
import Modal from './modal';
import Faq from './faq';
import Privacy from './privacy';
import Terms from './terms';
import Contact from './contact';

const taglines = [
	"tell us what you like, we tell you who to meet", 
	"skip the crowd, meet your tribe",
	"small group, big heart",
	"your people are looking for you",
]

export default function About() {
	const [currentTaglineIndex, setCurrentTaglineIndex] = useState(0);
	const [fade, setFade] = useState(false);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setFade(true)
			setTimeout(() => {
				setCurrentTaglineIndex((prevIndex) => (prevIndex + 1) % taglines.length)
				setFade(false)
			}, 500)
		}, 4000)

		return () => clearInterval(intervalId)
	}, [])

	return (
		<div className="flex flex-col items-center justify-between h-full overflow-y-auto text-gray-800 p-4">
			<div>
				<img src="/assets/logo-beta.png" alt="logo" className="w-256 mx-auto mb-4"/>
				<div 
					id="tagline" 
					className={`text-center font-cambria italic text-xl text-rose-600 mb-6 transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}
				>
					{taglines[currentTaglineIndex]}
				</div>
				
				{/* <p className="text-center mb-6">Join us in experimenting with a new way to find interesting people around you!</p> */}

				<div className="mb-6 space-y-1">
					<p>Get invited to small chat groups with people near you, each around a topic you all share an interest in.</p>
					<p>Have a good chat, and if things click, take it offline!</p>
				</div>				
				<p className="mb-4 pl-4 font-bold text-sm text-rose-600">How?</p>
				<ul className="list-disc pl-4 mb-6 space-y-1 text-sm">
					<li className="pl-2">Quick, free signup: pick your area and write a few words about yourself.</li>
					<li className="pl-2">If you want, chat with our bot to refine your profile for even better group matches.</li>
					<li className="pl-2">While waiting for group matches, get personalized recommendations for books, movies, places, and more.</li>
				</ul>
				<p className="mb-4 pl-4 font-bold text-sm text-rose-600">Why?</p>
				<ul className="list-disc pl-4 mb-6 space-y-1 text-sm">
					<li className="pl-2">There are more interesting people around us than we know -- it's just hard to find them.</li>
					<li className="pl-2">Random encounters at the pub are too hit-or-miss.</li>
					<li className="pl-2">Social networks and online forums are crowded and noisy, and they don't focus on offline proximity.</li>
					<li className="pl-2">Reviewing profiles one by one in friend-finder apps is tedious. Plus, small-group meetings are more relaxed than one-on-one.</li>
					<li className="pl-2">We offer small groups of neighbors, pre-selected for their interest in topics you like.</li>
				</ul>	
			</div>

			<footer className="mt-auto pt-8 text-center text-gray-600">
				<p className="space-x-2">
					<Modal triggerText="FAQ">
						<Faq />
					</Modal>
					<Modal triggerText="Terms">
						<Terms />
					</Modal>
					<Modal triggerText="Privacy">
						<Privacy />
					</Modal>
					{/* <a href="mailto:&#105;&#110;&#102;&#111;&#64;&#104;&#97;&#110;&#103;&#97;&#114;&#111;&#117;&#110;&#100;&#46;&#108;&#105;&#118;&#101;" className="hover:underline">Contact</a> */}
					<Modal triggerText="Contact">
						<Contact />
					</Modal>
				</p>
			</footer>
		</div>
	)
}
